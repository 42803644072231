const DataUtils = {
  convertData(data) {
    let newData = [];

    let convertMap = data.convertData,
      compactData = data.compactData;

    if (convertMap && Object.keys(convertMap).length !== 0) {
      compactData.forEach((item) => {
        let obj = {};
        for (var key in convertMap) {
          obj[key] = item[convertMap[key]];
        }
        newData.push(obj);
      });
      return newData;
    } else {
      return data.data;
    }
  },
};
export default DataUtils;
