import { useEffect, useMemo, useRef } from "react";
import { useSelector } from "react-redux";

import rmaDeclarationClient from "@/apis/rmaDeclarationClient";
import SntViewIcon from "@/components/Icons/SntViewIcon";
import SntDateView from "@/components/ReactBootstrap/SntDateView";
import SntDownloadButton from "@/components/ReactBootstrap/SntDownloadButton";
import SntInfoPanel from "@/components/SntInfo/SntInfoPanel";
import { useOrg } from "@/contexts/OrgContext";
import SntActionButton from "../../components/ReactBootstrap/SntActionButton";
import SntAdvancedSearchTableViewCard from "../../components/SntTableViewCard/SntAdvancedSearchTableViewCard";
import AdminLayout from "../../containers/AdminLayout/AdminLayout";
import ActionToolEnum from "../../containers/AdminLayout/Toolbar/ActionToolEnum";
import ActionToolType from "../../containers/AdminLayout/Toolbar/ActionToolType";
import RMAStatusLabel from "./RMAStatusLabel";

const PAGE_NAME = "RMA_DECLARATION_VIEW";
const DEFAULT_OPTION = {
  pageName: PAGE_NAME,
  queryObjectType: "RMA_DECLARATION",
  filtersVisibleAtInit: ["zendeskId", "status", "description"],
  optionsSettings: {},
};

const RMADeclarationView = ({ reload }) => {
  //redux state
  const language = useSelector((state) => state.language);
  const table = useRef();
  const { orgId } = useOrg();

  const filterList = [
    {
      id: ActionToolEnum.save_filter,
      title: language.save_filter,
      type: ActionToolType.filter,
    },
    {
      id: ActionToolEnum.load_filter,
      title: language.load_filter,
      type: ActionToolType.filter,
    },
  ];

  useEffect(() => {
    // reload button
    table.current && table.current.refresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgId, reload]);

  const allColumns = useMemo(
    () => [
      {
        key: "zendeskId",
        title: language.zendesk_support_id,
        disableSortBy: true,
        Cell: ({ cell, value }) => {
          return (
            <a
              href={cell.row.original.linkToZendesk}
              target="_blank"
              rel="noreferrer"
            >
              {value}
            </a>
          );
        },
      },
      {
        key: "status",
        title: language.status_key,
        disableSortBy: true,
        Cell: ({ cell, value }) => {
          return <RMAStatusLabel value={value} other="" />;
        },
      },
      {
        key: "deviceSerials",
        title: language.number_of_trackers_key,
        disableSortBy: true,
        Cell: ({ cell, value }) => {
          return value?.length || "";
        },
      },
      {
        key: "description",
        title: language.description_key,
        disableSortBy: true,
        Cell: ({ cell, value }) => {
          return value || "";
        },
      },
      {
        key: "internalDescription",
        title: language.internal_description,
        disableSortBy: true,
        Cell: ({ cell, value }) => {
          return value || "";
        },
      },
      {
        key: "handledByPartner",
        title: language.handled_by_partner,
        Cell: ({ cell, value }) => {
          return value || "";
        },
      },
      {
        key: "createdAt",
        title: language.created_date_key,
        Cell: ({ value }) => {
          return (
            <SntDateView
              value={value}
              other={language.na_key}
              format="lll"
            ></SntDateView>
          );
        },
      },
      {
        key: "closedAt",
        title: language.closed_key,
        Cell: ({ value }) => {
          return (
            <SntDateView
              value={value}
              other={language.na_key}
              format="lll"
            ></SntDateView>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const defaultColumnInit = useMemo(
    () => ["zendeskId", "status", "deviceSerials", "description", "createdAt"],
    []
  );

  const extraCols = useMemo(
    () => [
      {
        key: "actions",
        title: language.actions_key,
        immovable: true,
        disableSortBy: true,
        canResize: false,
        Cell: ({ cell }) => {
          let full = cell.row.original;
          return (
            <div>
              <SntActionButton
                title={language.view_key}
                to={`/rma_declaration_view_details?id=${full.id}`}
              >
                <SntViewIcon />
              </SntActionButton>
              <SntDownloadButton
                title={language.download_key}
                linkDownload={rmaDeclarationClient.getDownloadLink(full.id)}
              />
            </div>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const onDataRequestChange = (data, callback) => {
    const { params, dataOptions } = data;

    if (table.current) {
      params.selectedOrgId = orgId;
      table.current.setLoading(true);
      rmaDeclarationClient.search(params).then(
        ({ data }) => {
          if (table.current) {
            table.current.updateDataTable({
              ...data,
              pageCount: data.countByFilter,
              totalItem: {
                countAll: data.countAll,
                countByFilter: data.countByFilter,
              },
            });
            table.current.setLoading(false);

            const dataCallback = table.current.getDataCallBack(
              params,
              dataOptions,
              data
            );
            callback(dataCallback[0], dataCallback[1]);
          }
        },
        (err) => {
          table.current.setLoading(false);
        }
      );
    }
  };

  return (
    <div>
      <AdminLayout
        browserTabTitle={language.device_return}
        headerTitle={language.device_return}
        reload={reload}
        pageName={PAGE_NAME}
        sharedKey={PAGE_NAME}
        defaultOption={DEFAULT_OPTION}
        isAdvanceFilter={true}
        filterList={filterList}
        newButton={{
          disabled: true,
        }}
        topContent={() => (
          <SntInfoPanel>
            <p>
              {language.desc_about_the_return_process}
              <br />
              {language.desc_about_the_return_process_1}
              <br />
              {language.desc_about_the_return_process_2}
              <br />
              {language.desc_about_the_return_process_3}
              <br />
              {language.desc_about_the_return_process_4}
            </p>
          </SntInfoPanel>
        )}
        totalLabel={language.items_key}
        body={({ isDisabled, dataOptions, onChangeChildren }) =>
          dataOptions && (
            <SntAdvancedSearchTableViewCard
              shared={(child) => (table.current = child)}
              allColumns={allColumns}
              extraCols={extraCols}
              visibleCols={defaultColumnInit}
              disabled={isDisabled}
              onChange={(data) => {
                onDataRequestChange(data, onChangeChildren);
              }}
              dataOptions={dataOptions}
            />
          )
        }
      ></AdminLayout>
    </div>
  );
};

export default RMADeclarationView;
